import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import useToast from 'components/toast/useToast';

export interface ICopyButtonProps {
  message:string;
}
export default function CopyButton({ message }:ICopyButtonProps) {
  const { createInfoToast } = useToast();
  return <IconButton
    sx={{
      '&&': {
        padding: '0 0 0 8px',
      },
    }}
    onClick={() => {
      navigator.clipboard.writeText(message).catch(reason => console.log(reason));
      createInfoToast('Copied to clipboard');
    }}
  >
    <ContentCopyIcon/>
  </IconButton>;
}