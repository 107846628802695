import get from 'lodash/get';
import { useCallback } from 'react';

export default function useErrorState({ formState }:any) {
  const { errors, dirtyFields } = formState;
  const isError = useCallback(
    (propertyPath:string) => {
      return Boolean(get(errors, propertyPath+'.type'));
    },
    [errors, dirtyFields],
  );
  const getErrorMessage = useCallback(
    (propertyPath:string) => {
      return get(errors, propertyPath)?.message;
    },
    [errors],
  );
  return {
    isError,
    getErrorMessage,
  };
}