import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { IconButton, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isEqual, isValid } from 'date-fns';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { formatLongMonthDayYear } from 'util/dateUtil';

export interface IHookDateRangePickerProps {
  name:string;
  labelStart:string;
  disabled?:boolean;
  labelEnd:string;
  required?:boolean;
  validationRules?: any;
  dateRangePickerProps?:any;
  textFieldProps?:any;
}


function renderValue(dates:Date[]) {
  if (dates.length > 1) {
    let startDate = dates[0];
    let endDate = dates[1];
    if (startDate && endDate && isValid(startDate) && isValid(endDate) && !isEqual(startDate, endDate)) {
      return `${formatLongMonthDayYear(startDate)} - ${formatLongMonthDayYear(endDate)}`;
    } else if (startDate && isValid(startDate)) {
      return `${formatLongMonthDayYear(startDate)}`;
    }
  } else if (dates.length > 0) {
    let startDate = dates[0];
    if (isValid(startDate)) {
      return `${formatLongMonthDayYear(startDate)}`;
    }
  }
  return '';
}

export default function HookDateRangePicker({ name, labelStart, disabled, labelEnd, required, validationRules, dateRangePickerProps, textFieldProps }:IHookDateRangePickerProps) {
  const methods = useFormContext();
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  const [open, setOpen] = useState<boolean>(false);
  const rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    ...validationRules,
  };
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        ...parmas
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: labelStart, end: labelEnd }}>
            <MobileDateRangePicker
              DialogProps={{
                PaperProps: { className: 'newServiceDateModalBody' },
              }}
              disabled={disabled}
              value={value}
              onChange={(newValues) => {
                onChange(newValues);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onOpen={() => {
                setOpen(true);
              }}
              open={open}
              renderInput={({ inputProps, ...startProps }, endProps) => {
                const startValue = inputProps?.value;
                delete inputProps?.value;

                return (
                  <>
                    <TextField
                      sx={{
                        '& .MuiFilledInput-root': {
                          padding: '0',
                        },
                        '& .MuiFilledInput-input': {
                          paddingLeft: '0',
                        },
                        '& .MuiInputLabel-root': {
                          transform: 'translate(0px, 7px) scale(0.75)',
                        },
                        '& .MuiFormHelperText-root.Mui-error': {
                          marginLeft: '0',
                        },
                      }}
                      variant='filled'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            disabled={startProps.disabled}
                            onClick={(e) => {
                              if (!startProps.disabled) {
                                setOpen(true);
                              }
                            }}>
                            <InsertInvitationIcon sx={{ color: startProps.disabled ? theme.palette.grey[400] : theme.palette.primary.main }}/>
                          </IconButton>),
                      }}
                      {...startProps}
                      inputProps={inputProps}
                      error={isError(name)}
                      helperText={(isError(name) && getErrorMessage(name)) || ' '}
                      value={renderValue(value)}
                      {...textFieldProps}
                    />
                  </>
                );
              }}
              {...dateRangePickerProps}
            />
          </LocalizationProvider>
        );
      }
      }
    />
  );
}