
export function getStoredSaveQuickSearch(storageKey:string):boolean {
  const currentValue = localStorage.getItem(`${storageKey}.saveQuickSearch`);
  if (currentValue !== '' && currentValue !== undefined && currentValue !== null) {
    return JSON.parse(currentValue);
  }
  return false;
}

export function setStoredSaveQuickSearch(storageKey:string, val:boolean) {
  if (!storageKey) {
    throw new Error('Must defined storageKey');
  }
  localStorage.setItem(`${storageKey}.saveQuickSearch`, JSON.stringify(val));
}

export function getStoredSearchValue(storageKey:string) {
  return localStorage.getItem(`${storageKey}.searchValue`);
}

export function setStoredSearchValue(storageKey:string, searchValue:string) {
  if (!storageKey) {
    throw new Error('Must defined storageKey');
  }
  localStorage.setItem(`${storageKey}.searchValue`, searchValue);
}